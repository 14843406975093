<template>
    <div  class="md-content" style="padding: 16px" >
        <div style="padding: 32px 16px 16px;text-align: center;">
            <div class="md-title" style="padding-bottom: 16px"> {{lang.info_sobre}} <br> {{lang.app_version}}: 1.0</div>
            <p  style="padding-bottom: 16px">{{lang.info_sobre_resumen}}.</p>
            <div class="md-subheading">{{lang.info_sobre_caracteristicas_incluidas}}</div>
        </div>
        <div class="full-control md-elevation-11" style="padding: 36px  16px; background-color: white">
            <md-list>
                <md-subheader>{{lang.caracteristicas}}</md-subheader>

                <md-list-item>
                    <md-checkbox v-model="notification" value="si" disabled />
                    <span class="md-list-item-text">{{lang.info_caracteristica_1}}</span>
                </md-list-item>

                <md-list-item>
                    <md-checkbox v-model="notification" value="si" disabled />
                    <span class="md-list-item-text">{{lang.info_caracteristica_2}}</span>
                </md-list-item>

                <md-list-item>
                    <md-checkbox v-model="notification" value="si" disabled />
                    <span class="md-list-item-text">{{lang.info_caracteristica_3}}</span>
                </md-list-item>

                <md-list-item>
                    <md-checkbox v-model="notification" value="si" disabled />
                    <span class="md-list-item-text">{{lang.info_caracteristica_4}}</span>
                </md-list-item>

                <md-list-item>
                    <md-checkbox v-model="notification" value="si" disabled />
                    <span class="md-list-item-text">{{lang.info_caracteristica_5}}</span>
                </md-list-item>

                <md-list-item>
                    <md-checkbox v-model="notification" value="si" disabled />
                    <span class="md-list-item-text">{{lang.info_caracteristica_6}}</span>
                </md-list-item>

                <md-list-item>
                    <md-checkbox v-model="notification" value="no" disabled/>
                    <span class="md-list-item-text">{{lang.info_caracteristica_7}}</span>
                </md-list-item>
                <md-list-item>
                    <md-checkbox v-model="notification" value="no" disabled/>
                    <span class="md-list-item-text">{{lang.info_caracteristica_8}}</span>
                </md-list-item>
                <md-list-item>
                    <md-checkbox v-model="notification" value="no" disabled/>
                    <span class="md-list-item-text">{{lang.info_caracteristica_9}}</span>
                </md-list-item>
            </md-list>

            <md-list v-if="false">
                <md-subheader>Settings</md-subheader>

                <md-list-item>
                    <md-icon>network_wifi</md-icon>
                    <span class="md-list-item-text">WiFi</span>
                    <md-switch v-model="settings.wifi" />
                </md-list-item>

                <md-list-item>
                    <md-icon>bluetooth</md-icon>
                    <span class="md-list-item-text">Bluetooth</span>
                    <md-switch v-model="settings.bluetooth" />
                </md-list-item>

                <md-list-item>
                    <md-icon>data_usage</md-icon>
                    <span class="md-list-item-text">Data Usage</span>
                </md-list-item>
            </md-list>

            <md-list v-if="false">
                <md-subheader>Ringtone</md-subheader>

                <md-list-item>
                    <md-radio v-model="ringtone" value="joy" />
                    <span class="md-list-item-text">Joy</span>
                </md-list-item>

                <md-list-item>
                    <md-radio v-model="ringtone" value="metal" />
                    <span class="md-list-item-text">Metal</span>
                </md-list-item>

                <md-list-item>
                    <md-radio v-model="ringtone" value="peace" />
                    <span class="md-list-item-text">Peace</span>
                </md-list-item>

                <md-list-item>
                    <md-radio v-model="ringtone" value="rock-night" />
                    <span class="md-list-item-text">Rock night</span>
                </md-list-item>
            </md-list>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AppInfo',
        computed:{
            lang(){ return this.$store.state.lang}
        },
        data: () => ({
            ringtone: 'peace',
            settings: {
                wifi: true,
                bluetooth: false
            },
            notification: ['si']
        }),
        async beforeMount(){
            if(!this.$store.state.lang) this.$store.dispatch('getServerData');
        },
        mounted() {
            this.$store.commit('setTitle', this.lang.acerca_app)
        }
    }
</script>
<style lang="scss" scoped>
    .md-content{
        position: relative;
        height: calc(100vh - 56px);
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;

        .md-list-item-content.md-ripple {
            .md-list-item-text {
                white-space: pre-wrap;
            }
        }
    }
</style>